//import React, { Suspense } from "react"
import React from "react"
//import { Container } from "react-bootstrap"
import { AuthProvider } from "./contexts/AuthContext"
import {BrowserRouter as Router, Switch, Route } from "react-router-dom"
//import Dashboard from "./Dashboard"
import Login from "./components/Login"
import Signup from "./components/Signup"
import PrivateRoute from "./PrivateRoute"
import AppWithContexts from "./AppWithContexts"
import "./App.css"

export const AppWithRouting = () => {
  return (
          <Router>
            <AuthProvider>
              <Switch>
                <PrivateRoute exact path="/" component={AppWithContexts} />
                <Route path="/signup" component={Signup} />
                <Route path="/login" component={Login} />
              </Switch>
            </AuthProvider>
          </Router>
  )
}
export default AppWithRouting
